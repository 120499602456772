/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const PREV_PATHNAME_KEY = 'previous-pathname'

const isFindAJobPage = (pathname) =>
  pathname.includes('/find-a-job/') || pathname.includes('/hledam-praci/')

const isPositionDetailPage = (pathname) =>
  new RegExp('/find-a-job/open-positions/.*/').test(pathname) || new RegExp('/hledam-praci/otevrene-pozice/.*/').test(pathname)

const isThankYouPage = (pathname) =>
  pathname.includes('/find-a-job/open-positions/thank-you/') || pathname.includes('/hledam-praci/otevrene-pozice/dekujeme/')

const smartsuppApi = (command, callIdx) => {
  callIdx = callIdx || 0;
  if (window.smartsupp) {
    console.log('Smartsupp api:', command);
    window.smartsupp(command);
  } else if (callIdx < 100) {
    // smartsupp is not loaded yet, so we try it after 100ms.
    // If the smartsupp is not loaded until 5seconds, we stop trying to send the command in order to save resources
    setTimeout(() => smartsuppApi(command, callIdx + 1), 50);
  }
};

export const onRouteUpdate  = ({ location }) => {
  let previousPathName = localStorage.getItem(PREV_PATHNAME_KEY) || '';
  if (typeof previousPathName !== "string") {
    previousPathName = '';
  }

  if (!isPositionDetailPage(location.pathname) && !isThankYouPage(location.pathname) && isPositionDetailPage(previousPathName)) {
    // add 'left-position-detail' query param to URL in order to indicate that user left the position detail
    // and to let the SmartsUpp chat know to send the automatic message
    window.history.replaceState(window.history.state, null, location.pathname + '?left-position-detail');
    setTimeout(() => smartsuppApi('chat:show'), 1000);
  } else if (isFindAJobPage(location.pathname)) {
    // show chatbot on Find Job pages
    setTimeout(() => smartsuppApi('chat:show'), 1000);
  }  else {
    smartsuppApi('chat:hide');
  }

  localStorage.setItem(PREV_PATHNAME_KEY, location.pathname);
};
