module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Iconity","short_name":"Iconity","start_url":"/","background_color":"#e9ebed","theme_color":"#363d4a","display":"browser","icon":"src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84f2ddfa5aec5dc8e30affb3fa1aee46"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-54HHQD4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
